import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

function App() {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [userInformation, setUserInformation] = useState('');
  const [authid, setAuthid] = useState('');
  const [id, setId] = useState('');
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [confirmOptions, setConfirmOptions] = useState(false);
  const [confirmation, setConfirmations] = useState(true);
  const [doSetQuestionFlag, setDoSetQuestionFlag] = useState(true);
  const [wait, setWait] = useState(false);
  const [greeting, setGreeting] = useState('');
  const [ending, setEnding] = useState('');
  const [role, setRole] = useState('');
  // const [questions, setQuestions] = useState([]);
  const questionsRef = useRef([]);
  const [tempOption, setTempOption] = useState([]);
  // const [tempQuestion, setTempQuestion] = useState([]);
  const tempQuestionRef = useRef([]);
  const chatContainerRef = useRef();
  const [conversation, setConversation] = useState([]);
  const [part, setPart] = useState(1);
  const [entryQuestionsArray, setEntryQuestionsArray] = useState([]);
  const questionCountRef = useRef(0);
  const questionScopeRef = useRef("");
  const userQuestionCountRef = useRef(0);
  const talkGreetingRef = useRef("");

  const fetchDataFromBackend = async (jsonData, url) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // 获取查询参数部分
    const queryParams = new URLSearchParams(window.location.search);
    // 获取特定键的值
    const idParam = queryParams.get('id');
    console.log(idParam);
    setId(idParam);
    const jsonData = {
      "uuid": idParam,
      "flag": 0,
      "database": "interview"
    };
    fetchDataFromBackend(jsonData, 'https://userchat.854799920.workers.dev/').then((data) => {
      console.log(data);
      setEnding(data.message[0].ending);
      setGreeting(data.message[0].greeting);
      handleAssistantMessage(data.message[0].greeting);
      console.log(data);
      setRole(data.message[0]['persona_prompt']);
      setEntryQuestionsArray(data.message[0]['entry_question']['questionGroup']);
      setAuthid(data.message[0]['user_id']);
      //This is only for the 'base-question-prompt' created by cms
      // const jsonQuestions = JSON.parse(data.message[0]['base-question-prompt']);
      // console.log(jsonQuestions);
      // setQuestions(jsonQuestions.questions);

      questionCountRef.current = data.message[0]['free_talk']['questionCount'];
      questionScopeRef.current = data.message[0]['free_talk']['scope'];
      talkGreetingRef.current = data.message[0]['free_talk']['talkGreeting'];
      questionsRef.current = data.message[0]['base_question'];
    });

  }, []);


  // 动态生成的选项数据
  const [options, setOptions] = useState([
    'Option 1',
    'Option 2',
    // Add more options as needed
  ]);
  const handleSetOptions = (newOptions) => {
    setOptions(newOptions);
  };


  const handleOptionClick = (option) => {
    const newMessage = {
      id: uuidv4(),
      text: option,
      sender: 'user',
    };
    setTempOption(option);
    setMessages((messages) => [...messages, newMessage]);
    setConversation((conversation) => [...conversation, "user:" + option]);
    setShowOptions(false);
    setConfirmOptions(true);
    handleAssistantMessage("Do you confirm your answer?");
    setIsInputDisabled(true);
  };

  const handleConfirmYES = (YES) => {
    const newMessage = {
      id: uuidv4(),
      text: YES,
      sender: 'user',
    };
    setMessages((messages) => [...messages, newMessage]);
    setConversation((conversation) => [...conversation, "user:YES"]);
    setConfirmations(true);
    setConfirmOptions(false);
    setIsInputDisabled(true);
  };

  const handleConfirmNO = (NO) => {
    const newMessage = {
      id: uuidv4(),
      text: NO,
      sender: 'user',
    };
    setMessages([...messages, newMessage]);
    setConversation((conversation) => [...conversation, "user:NO"]);
    setConfirmations(false);
    setConfirmOptions(false);
    setIsInputDisabled(true);
  };


  const handleSendMessage = async () => {
    if (userInput.trim() === '') return;
    const newMessage = {
      id: uuidv4(),
      text: userInput.trim(),
      sender: 'user',
    };
    setWait(true);
    setMessages((messages) => [...messages, newMessage]);
    setConversation((conversation) => [...conversation, "user:" + userInput.trim()]);
    setUserInput('');

    if (part == 1 || part == 2) {
      const updatedQueue = [...questionsRef.current];
      const question = updatedQueue.shift(); // 从队头移除项
      console.log("Q:" + question.question);
      console.log("part:" + part);
      const jsonData = {
        "question": "",
        "message": {
          "assistant": question.question,
          "user": userInput.trim()
        },
        "flag": 0,
        "role": role
      };
      setIsInputDisabled(true);
      const releventData = await fetchDataFromBackend(jsonData, 'https://gpthr.854799920.workers.dev/');
      try {
        const jsonResponse = JSON.parse(releventData.message);
        setWait(false);
        if (jsonResponse.isRelevant) {
          let keywordsElements = [];
          if (question.hasOwnProperty('follow-up')) {
            if (question['follow-up'].some(obj => obj.hasOwnProperty('keywords'))) {
              console.log(111);
              keywordsElements = question['follow-up'].flatMap(element => element.keywords);
              console.log(keywordsElements);
            }
          }
          if (part == 2 && keywordsElements.length > 0) {
            let jsonData = {
              "question": "",
              "message": {
                "assistant": keywordsElements,
                "user": userInput.trim()
              },
              "flag": 1,
              "role": role
            }
            const keywordsData = await fetchDataFromBackend(jsonData, 'https://gpthr.854799920.workers.dev/');
            const keywordsJsonData = JSON.parse(keywordsData.message);
            if (keywordsJsonData.isRelevant) {
              const foundQuestion = question['follow-up'].find(element => element.keywords.includes(keywordsJsonData.keyword));
              console.log("foundQuestion:" + JSON.stringify(foundQuestion));
              questionsRef.current.push(foundQuestion);
            }
          }
          handleAssistantMessage("Do you confirm your answer?");
          setConfirmOptions(true);
        } else {
          handleAssistantMessage(jsonResponse.response);
          setIsInputDisabled(false);
        }
      } catch (error) {
        console.log("System error:" + error);
        handleAssistantMessage("Sorry,Please answer " + question.question);
        setIsInputDisabled(false);
      }
    } else if (part == 3) {
      userQuestionCountRef.current = userQuestionCountRef.current + 1;
      console.log("userQuestionCount:" + userQuestionCountRef.current);
      console.log("QuestionCount:" + questionCountRef.current);
      let jsonData = {
        "question": "",
        "message": {
          "assistant": "'" + conversation + "'",
          "user": "User Information:" + userInformation + "User Question:" + userInput.trim()
        },
        "flag": 3,
        "role": role + " You can only talk in Conversation Scope:" + questionScopeRef.current
      };
      console.log("free-talk-json:" + JSON.stringify(jsonData));
      setIsInputDisabled(true);
      const releventData = await fetchDataFromBackend(jsonData, 'https://gpthr.854799920.workers.dev/');
      console.log(JSON.stringify(releventData));
      handleAssistantMessage(releventData.message);
      setIsInputDisabled(false);
      if (userQuestionCountRef.current == questionCountRef.current) {

        handleAssistantMessage(ending);
        const jsonData = {
          "interview_id": id,
          "record": JSON.stringify([...messages]),
          "summary": userInformation,
          "auth_id":authid,
          "flag": 1
        };
        fetchDataFromBackend(jsonData, 'https://userchat.854799920.workers.dev/');

      }
    }

  };

  const handleAssistantMessage = (text) => {
    const newMessage = {
      id: uuidv4(),
      text: text,
      sender: 'assistant',
    };
    setConversation((conversation) => [...conversation, "assistant:" + text]);
    try {
      setMessages((messages) => [...messages, newMessage]);
    } catch (error) {
      console.log("assistant message error:" + error);
      const newMessage = {
        id: uuidv4(),
        text: "could you send message again?",
        sender: 'assistant',
      };
      setMessages((messages) => [...messages, newMessage]);
    }

  }

  useEffect(() => {

    if (messages[messages.length - 1]?.sender === 'user') {
      setTimeout(() => {
        if (wait == true) {
          return;
        }
        if (part == 1 || part == 2) {
          const updatedQueue = [...questionsRef.current];
          if (updatedQueue.length > 1 && confirmation == true) {
            //用户确认为true且question队列还有剩余时，进行下一个question询问
            const question = updatedQueue.shift(); // 从队头移除项
            if (question.hasOwnProperty('options')) {
              //如果option下方有分支时，进行分支的确认及放入队列
              console.log("tempOption:" + tempOption);
              const foundOption = question.options.find(option => option.text == tempOption);
              console.log("foundOption:" + JSON.stringify(foundOption));
              if (foundOption.hasOwnProperty('follow-up')) {
                console.log("follow-up:" + JSON.stringify(foundOption['follow-up']));
                updatedQueue.unshift(...foundOption['follow-up']);
              }
            }
            setDoSetQuestionFlag(true);
            questionsRef.current = updatedQueue;
            console.log(JSON.stringify(questionsRef.current));
          } else if (updatedQueue.length >= 1 && confirmation == false) {
            //用户确认为false且还有剩余时，通过将question队列重新赋值（赋值仍不变）触发再次询问
            setDoSetQuestionFlag(true);
            questionsRef.current = updatedQueue;
          } else {
            let flag = 0;
            if (tempOption.length > 0) {
              const question = updatedQueue.shift(); // 从队头移除项
              if (question.hasOwnProperty('options')) {
                //如果option下方有分支时，进行分支的确认及放入队列
                console.log("tempOption:" + tempOption);
                const foundOption = question.options.find(option => option.text == tempOption);
                console.log("foundOption:" + JSON.stringify(foundOption));
                if (foundOption.hasOwnProperty('follow-up')) {
                  console.log("follow-up:" + JSON.stringify(foundOption['follow-up']));
                  updatedQueue.unshift(...foundOption['follow-up']);
                  setDoSetQuestionFlag(true);
                  questionsRef.current = updatedQueue;
                  flag = 1;
                }
              }
              if(flag == 0){
                updatedQueue.unshift(question);
              }
              
            } 
            if(flag == 0){
              if (part == 1) {
                //part1 阶段结束
                let informationJsonData = {
                  "question": "",
                  "message": conversation,
                  "flag": 2,
                  "role": role
                };
                fetchDataFromBackend(informationJsonData, 'https://gpthr.854799920.workers.dev/').then((data) => {
                  console.log("User Inoframtion:" + data['message']);
                  setUserInformation(data['message']);
                });
                updatedQueue.shift();
                setConversation([]);
                const entryQuestionQueue = [...entryQuestionsArray];
                let jsonData = {
                  "uuid": entryQuestionQueue.shift(),
                  "flag": 0,
                  "database": "question_groups"
                };
                fetchDataFromBackend(jsonData, 'https://userchat.854799920.workers.dev/').then((data) => {
                  let initQuestion = {
                    "question": data.message[0]['init_question'],
                    "follow-up": data.message[0]['questions']
                  }
                  updatedQueue.unshift(initQuestion);
                  questionsRef.current = updatedQueue;
                });
                setEntryQuestionsArray(entryQuestionQueue);
                setPart(2);
  
              } else if (part == 2) {
                if (entryQuestionsArray.length > 0) {
                  updatedQueue.shift();
                  const entryQuestionQueue = [...entryQuestionsArray];
                  const uuid = entryQuestionQueue.shift();
                  console.log("entryQuestionUUid:" + uuid);
                  let jsonData = {
                    "uuid": uuid,
                    "flag": 0,
                    "database": "question_groups"
                  };
                  fetchDataFromBackend(jsonData, 'https://userchat.854799920.workers.dev/').then((data) => {
                    let initQuestion = {
                      "question": data.message[0]['init_question'],
                      "follow-up": data.message[0]['questions']
                    }
                    updatedQueue.unshift(initQuestion);
                    questionsRef.current = updatedQueue;
                    setEntryQuestionsArray(entryQuestionQueue);
                  });
                }
                else {
                  // part2 阶段结束
                  let informationJsonData = {
                    "question": "",
                    "message": conversation,
                    "flag": 2,
                    "role": role
                  };
                  fetchDataFromBackend(informationJsonData, 'https://gpthr.854799920.workers.dev/').then((data) => {
                    console.log("User Inoframtion:" + data['message']);
                    setUserInformation(userInformation + data['message']);
                  });
                  setConversation([]);
                  handleAssistantMessage(talkGreetingRef.current);
                  setPart(3);
                }
  
              }
            }

          }
        }
        setIsInputDisabled(false);
      }, 1000);
    }
  }, [messages]);

  useEffect(() => {
    if (questionsRef.current.length > 0 && doSetQuestionFlag == true) {
      const updatedQueue = [...questionsRef.current];
      const question = updatedQueue.shift(); // 从队头移除项
      console.log("Q_Array:" + JSON.stringify(question));
      let jsonData = null;
      if (part == 1) {
        jsonData = {
          "question": question.question,
          "message": "",
          "flag": 0,
          "role": role
        };
      }
      else if (part == 2) {
        jsonData = {
          "question": question.question + "Ask questions based on User Information:" + userInformation,
          "message": "",
          "flag": 0,
          "role": role
        };
      }
      fetchDataFromBackend(jsonData, 'https://gpthr.854799920.workers.dev/').then((data) => {
        const text = data['message'];
        console.log("Reply:" + text);
        handleAssistantMessage(text);

        if (question.hasOwnProperty('options')) {
          let options = [];
          for (let index = 0; index < question.options.length; index++) {
            options.push(question['options'][index]['text']);
          }
          handleSetOptions(options);
          setShowOptions(true);
          setIsInputDisabled(true);
        } else {
          setTempOption([]);
        }
        if (question.hasOwnProperty('follow-up') && confirmation == true) {
          if (part == 1) {
            updatedQueue.unshift(...question['follow-up']);
          }
          else if (part == 2) {
            const emptyKeywordsElements = question['follow-up'].filter(obj => !obj.hasOwnProperty('keywords'));
            updatedQueue.unshift(...emptyKeywordsElements);
          }
          updatedQueue.unshift(question);
          setDoSetQuestionFlag(false);
          questionsRef.current = updatedQueue;
        }
      });

    }
  }, [questionsRef.current]);

  useEffect(() => {
    // 消息更新后滚动到底部
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [messages]);

  return (
    <div className="flex flex-col h-screen">
      {/* 聊天消息区域 */}
      <div
        className="bg-white p-4 shadow-md rounded-md flex-1 overflow-y-auto space-y-4"
        ref={chatContainerRef}
      >
        {messages.map((message) => (
          <div
            key={message.id}
            className={`${message.sender === 'user' ? 'text-right' : 'text-left'
              }`}
          >
            <div
              className={`${message.sender === 'user'
                ? 'bg-blue-500 text-white rounded-tl-md rounded-br-md rounded-tr-md'
                : 'bg-green-500 text-white rounded-tl-md rounded-bl-md rounded-tr-md'
                } py-2 px-4 break-all max-w-md inline-block`}
            >
              {message.text}
            </div>
          </div>
        ))}
      </div>

      {/* 选项按钮区域 */}
      {showOptions && (
        <div className="bg-white p-4 shadow-md rounded-md mt-4">
          <div className="flex justify-center space-x-4">
            {options.map((option) => (
              <button
                key={option}
                className="bg-blue-500 text-white rounded-lg px-4 py-2"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* 验证按钮区域 */}
      {confirmOptions && (
        <div className="bg-white p-4 shadow-md rounded-md mt-4">
          <div className="flex justify-center space-x-4">
            <button
              key='YES'
              className="bg-green-500 text-white rounded-lg px-4 py-2"
              onClick={() => handleConfirmYES('YES')}
            >
              YES
            </button>
            <button
              key='NO'
              className="bg-red-500 text-white rounded-lg px-4 py-2"
              onClick={() => handleConfirmNO('NO')}
            >
              NO
            </button>
          </div>
        </div>
      )}

      {/* 用户输入区域 */}
      <div className="bg-white mt-4 p-4 shadow-md rounded-md">
        <div className="flex">
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            className="flex-1 rounded-l-lg border border-gray-300 p-2 focus:outline-none"
            placeholder={`${isInputDisabled ? 'Please waiting for the response' : 'Type your text'
              }`}
            disabled={isInputDisabled}
          />
          {!showOptions && !confirmOptions && (
            <button
              className={`rounded-r-lg px-4 py-2 ml-2 ${isInputDisabled ? 'bg-gray-500 text-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'
                }`}
              onClick={handleSendMessage}
              disabled={isInputDisabled}
            >
              Send
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
